<template>
  <div
    class="app-alert-box"
    :class="{ 'is-error': isError }"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
    @click="alertBoxStore.toggleAlertbox(true)"
    @keypress="alertBoxStore.toggleAlertbox(true)"
  >
    <div class="app-alert-box-slot">
      <div class="app-alert-box__content">
        <div class="app-alert-box__text">
          {{ text }}
        </div>
        <div class="app-alert-box__icon">
          <AppIcon :icon="isError ? 'alert-error' : 'alert-success'" :is-raw="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const alertBoxStore = useAlertBoxStore();
const { text, isError } = alertBoxStore.alertBoxContent;
</script>

<style lang="scss" scoped>
.app-alert-box {
    @include z-index('alertBox');
    @include fluid('padding-right', 100px, 160px);
    @include fluid('padding-left', 36px, 56px);
    @include fluid('padding-top', 25px, 45px);
    @include fluid('padding-bottom', 25px, 45px);

    position: fixed;
    top: 0;
    right: var(--page-padding);
    left: var(--page-padding);
    width: calc(100% - var(--page-padding) - var(--page-padding));
    max-width: 836px;
    border-radius: 0px 0px 12px 12px;
    margin: auto;
    background: $C_GREEN_LIGHT;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &.is-error {
        background: $C_RED_LIGHT;
    }
}

.app-alert-box-slot {
    @include fluid('column-gap', 20px, 20px);
    display: flex;
    width: 100%;
}

.app-alert-box__content {
    @include fluid('row-gap', 20px, 20px);

    display: flex;
    width: 100%;
    justify-content: space-between;
}

.app-alert-box__text {
    @include typo-font('regular');
    @include fluid('font-size', 16px, 26px);
}

.app-alert-box__icon {
    @include fluid('right', 36px, 56px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .app-icon {
        width: 50px;
        @include fluid('max-width', 35px, 50px);
    }
}
</style>
